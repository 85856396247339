body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #eee; */
}

p {
  line-height: 1.4;
}

a {
  text-decoration: none;
  color: #777;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.product-title p {
  text-align: center;
  margin: 0;
  /* font-weight: bold; */
  font-size: 28px;
  margin-bottom: 75px;
  margin-top: 0;
  color: grey;
}

.product-box {
  cursor: pointer;
  position: relative;
  width: 30%;
  box-shadow: gainsboro 0px 1px 5px 2px;
  /* margin: auto; */
}

/* .product-box:after {
  content: "";
  display: block;
  padding-bottom: 75%;
} */

.content {
  position: absolute;
  width: 100%;
  height: 100%;
}

.content img {
  width: 100%;
}

.readable-text p {
  text-align: left;
  margin: 0;
  color: grey;
}

.readable-text-about p {
  text-align: left;
  margin: 0;
  color: grey;
  line-height: initial;
}

.readable-text-services p {
  margin: 16px 0;
}

.readable-text-serviceslanding {
  line-height: 1.4;
}

.readable-text-serviceslanding ol{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.no-margin-top p{
  margin-top: 0;
}

.readable-text-serviceslanding ul{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.product-detail-title {
  /* font-size: 24px; */
  color: #777;
}

.product-detail-title p {
  margin-top: 10px;
}

.product-detail-title p:first-child {
  margin-top: 0;
}

@media only screen and (max-width: 480px) {
  .readable-text p {
    margin-top: 15px;
    font-size: 14px;
  }

  .readable-text-about p {
    /* margin-top: 15px; */
    font-size: 14px;
  }

  .readable-text-services p {
    font-size: 14px;
  }

  .readable-text-serviceslanding p {
    font-size: 14px;
  }
}

.readable-text strong {
  color: #777;
}

.strong-heading {
  color: #777;
  font-weight: bold;
}

.brands-card {
  overflow: hidden;
  transition: .2s ease-in-out;
  cursor: pointer;
  margin: auto;
  display: flex;
  margin-bottom: 20px;
  width: 95%;
  padding-bottom: 20px;
  border-bottom: 1px solid gainsboro;
  /* box-shadow: 1px 1px 4px 0px gainsboro; */
}

.brands-card:hover {
  /* box-shadow: 1px 1px 4px 2px gainsboro; */
}

.brands-listing-container {
  overflow: hidden;
}
.img-logo-landing-brands {
  height: 100%;
  /* height: 150%; */
  /* top: -25%; */
  position: relative;
}

.img-logo-landing {
  width: 75%;
  transition: .3s ease-in-out;
  filter: grayscale(100%);
  opacity: .8;
  cursor: pointer;
}

.img-logo-landing:hover {
  opacity: 1;
  filter: none;
}


.social-media-container {
  font-size: 30px;
  /* width: 60px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-media {
  padding-left: 20px;
  cursor: pointer;
  color: #777;
  transition: .3s ease-in-out;
}

.fa-whatsapp:hover {
  color: #1ebea5;
}

.fa-linkedin:hover {
  color: #0073b1;
}

.fa-youtube:hover {
  color: #FF0000; 
}

.fa-instagram:hover {
  color: #bc1888;
  /* background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);  */
}
/* #ff0000 */

.header-navigation-link {
  text-transform: uppercase;
  font-size: 14px;
  margin: 0 10px;
  display: inline-block;
  color: #777;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  transition: .3s ease-in-out;
  text-decoration: none;
}

.header-navigation-link:hover {
  /* color: dim#777; */
  /* font-weight: bold; */
  border-bottom: 1px solid #24326D;
  /* padding-bottom: 9px; */
}

.active-link {
  color: dim#777;
  font-weight: bold;
  /* border-bottom: 1px solid #24326D; */
  /* padding-bottom: 9px; */
}


.slick-dots {
  margin-bottom: 40px !important;
}

.slick-dots li.slick-active button:before {
  color: #777;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  position: absolute;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0;
  border-radius: 0;
  background: dim#777;
  -webkit-box-shadow: inset 0 0 0 transparent;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 transparent;
  -webkit-border-radius: 0;
  border-radius: 0;
}

a:active, a:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}

.header-mobile:active, .header-mobile:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}

.centered-text p {
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
}

form input {
  border: 1px solid gainsboro;
  box-sizing: border-box;
  margin: 10px 0;
  font-family: inherit;
  font-size: 16px;
  padding: 10px;
}

form textarea {
  border: 1px solid gainsboro;
  box-sizing: border-box;
  margin: 10px 0;
  font-family: inherit;
  font-size: 16px;
  padding: 10px;
  margin-bottom: 20px;
}

form button {
  border: 1px solid gainsboro;
  box-sizing: border-box;
  padding: 10px;
  border: none;
  background-color: dimgray;
  color: white;
  text-transform: uppercase;
  font-family: inherit;
  font-size: 18px;
  cursor: pointer;
}

.google-map {
  height: 250px;
  width: 100%;
}

.google-daruma-tooltip {
  position: absolute;
  color: black;
  padding: 10px;
  background: white;
  margin-top: -100px;
  margin-left: 12px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: 0.3s;
}

blockquote {
  background: white;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
  display: flex;
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
  margin-top: 15px;
}
blockquote p {
  display: inline;
}

.center {
  text-align: center;
}

.center p {
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .product-title p {
    font-size: 14px;
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
  }
  
  .main-title-text p{
    font-size: 16px;
  }

  form input {
    font-size: 14px;
  }
  
  form textarea {
    font-size: 14px;
  }
  
  form button {
    font-size: 14px;
  }
}

.blue-hover {
  transition: .3s ease-in-out;
}

.blue-hover:hover {
  background-color: #24326D !important;
}

button:focus {outline:0;}

.about-us-image {
  width: 100vw;
  height: 400px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  left: 0;
  top: 130px;
}

.about-us-container {
  position: relative;
  margin-top: 225px;
}

.about-us-info {
  background-color: white;
  padding: 30px;
  width: 55%;
  margin-left: -30px;
  z-index: 1;
  text-align: justify;
}

@media only screen and (max-width: 480px) {
  .about-us-info {
    width: 80%;
    margin: auto;
  }

  .about-us-info h1{
    text-align: center;
    font-size: 22px;
  }

  .about-us-info h6{
    text-align: center;
  }
}

ul {
  margin: 0;
  padding-left: 20px;
}
ul.dashed {
  list-style-type: none;
}
ul.dashed > li {
  text-indent: -5px;
}
ul.dashed > li:before {
  content: "-";
  text-indent: -5px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
}

.pagination li {
  cursor: pointer;
  color: indianred;
  /* border: 1px solid blueviolet; */
  height: 36px;
  text-align: center;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s ease-in-out;
}

.pagination .active {
  /* background-color: darkred; */
  color: darkred;
}

.pagination li a {
  padding: 7px;
  color: inherit !important;
}

.pagination li a:focus {
  outline: 0;
}

/* .pagination li:hover {
background-color: blueviolet;
color: white;
} */